import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import AppLayout from "./AppLayout";
import AuthWrapper from "./utils/AuthWrapper";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Drivers from "./pages/Drivers";
import SubAdmins from "./pages/SubAdmins";
import Riders from "./pages/Riders";
import Rentals from "./pages/Rentals";
import IntroScreens from "./pages/IntroScreens";
import Payments from "./pages/Payments";
import UserProfile from "./pages/UserProfile";
import LegalTerms from "./pages/LegalTerms";
import HowItWorks from "./pages/HowItWorks";
import BusinessSignup from "./pages/BusinessSignup";
import Corporates from "./pages/Corporates";
import CorporateHome from "./pages/CorporateHome";
import VerifyEmail from "./pages/VerifyEmail";
import CorporateUsers from "./pages/CorporateUsers";
import SubAdminAuthWrapper from "./utils/SubAdminAuthWrapper";
import Pricing from "./pages/Pricing";
import VerifyUserEmail from "./pages/VerifyUserEmail";
import {CoverageArea} from "./pages/CoverageArea";
import {SurgeArea} from "./pages/SurgeArea";
import {useAuth} from "./context/AuthContext";
import DriverAlerts from "./pages/DriverAlerts";
import Promotions from "./pages/Promotions";
import {Complaints} from "./pages/Complaints";

const AppRoutes = () => {
    const {isLoggedIn} = useAuth();
    return (
        <Routes>
            {/* Redirect to Dashboard if user is logged in */}
            {isLoggedIn ? (
                <Route index element={<Navigate to="/admin" replace />} />
            ) : (
                <Route index element={<Login />} />
            )}
            <Route index element={<Login/>}/>
            <Route path={'business-signup'} element={<BusinessSignup/>}/>
            <Route path="/verifyEmail/:token" element={<VerifyEmail/>}/>
            <Route path="/verifyUserEmail/:token" element={<VerifyUserEmail/>}/>

            {/* pages with layout and authentication*/}
            {/* <Route element={<AppLayout />}> */}
            {/* <Route element={<AuthWrapper />}> */}
            {/* <Route path="*" element={<AppLayout />}> */}
            <Route
                path="admin"
                element={
                    <AppLayout>
                        <AuthWrapper/>
                    </AppLayout>
                }
            >
                <Route index element={<Dashboard/>}/>
                <Route path="drivers" element={<Drivers/>}/>
                <Route path="corporates" element={<Corporates/>}/>
                <Route path="subAdmins" element={<SubAdmins/>}/>
                <Route path="riders" element={<Riders/>}/>
                <Route path="intro-screens" element={<IntroScreens/>}/>
                <Route path="rentals" element={<Rentals/>}/>
                <Route path="payments" element={<Payments/>}/>
                <Route path="pricing" element={<Pricing/>}/>
                <Route path="profile" element={<UserProfile/>}/>
                <Route path="legal" element={<LegalTerms/>}/>
                <Route path="howItWorks" element={<HowItWorks/>}/>
                <Route path="operational-zones" element={<CoverageArea/>}/>
                <Route path="surge-zones" element={<SurgeArea/>}/>
                <Route path="driver-alerts" element={<DriverAlerts/>}/>
                <Route path="promotions" element={<Promotions/>}/>
                <Route path="complaints" element={<Complaints/>}/>

            </Route>
            <Route
                path="corporate"
                element={
                    <AppLayout>
                        <AuthWrapper/>
                    </AppLayout>
                }
            >
                <Route index element={<CorporateHome/>}/>
                <Route path="users" element={<CorporateUsers/>}/>
            </Route>

            {/*<Route path="*" element={<Error404 />} />*/}
            <Route path="subAdmin"
                   element={<AppLayout>
                       <SubAdminAuthWrapper/>
                   </AppLayout>
                   }>
                {/*<Route path="subAdmin" element={<SubAdminNav />}>*/}
                {/*    <Route index element={<SubAdminHome />} />*/}
                <Route index="drivers" element={<Drivers/>}/>
                <Route path="riders" element={<Riders/>}/>
                {/*</Route>*/}
            </Route>

            <Route path="*" element={<Login/>}/>
        </Routes>
    );
};

export default AppRoutes;
