import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Grid, IconButton, Modal, Skeleton, TextField, Typography} from "@mui/material";
import {DrawingManager, GoogleMap, LoadScript} from "@react-google-maps/api";
import {GOOGLE_MAPS_API_KEY} from "../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import SurgeAreaCard from "../components/SurgeAreaCard";
import CoverageAreaCard from "../components/CoverageAreaCard";

const MemoizedGoogleMap = React.memo(({onLoad, onPolygonComplete}) => {
    return (
        <GoogleMap
            mapContainerStyle={{width: "100%", height: "500px"}}
            center={{lat: 37.0902, lng: -95.7129}}
            zoom={4}
            onLoad={onLoad}
        >
            <DrawingManager
                onPolygonComplete={onPolygonComplete}
                options={{
                    drawingControl: true,
                    drawingControlOptions: {drawingModes: ["polygon"]},
                    polygonOptions: {editable: true},
                }}
            />
        </GoogleMap>
    );
});

export const SurgeArea = () => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [surgeFactor, setSurgeFactor] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [polygonPath, setPolygonPath] = useState([]);
    const [nameError, setNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [surgeFactorError, setSurgeFactorError] = useState("");
    const [startTimeError, setStartTimeError] = useState("");
    const [endTimeError, setEndTimeError] = useState("");
    const [polygonError, setPolygonError] = useState("");
    const mapRef = useRef();
    const polygonRef = useRef(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setName("");
        setDescription("");
        setSurgeFactor("");
        setStartTime("");
        setEndTime("");
        setPolygonPath([]);
        setNameError("");
        setDescriptionError("");
        setSurgeFactorError("");
        setStartTimeError("");
        setEndTimeError("");
        setPolygonError("");
        if (polygonRef.current) polygonRef.current.setMap(null);
        setOpen(false);
    };

    const handlePolygonComplete = (polygon) => {
        if (polygonRef.current) polygonRef.current.setMap(null);
        const path = polygon.getPath().getArray().map((latLng) => ({lat: latLng.lat(), lng: latLng.lng()}));
        setPolygonPath(path);
        polygonRef.current = polygon;
        setPolygonError("");

        const bounds = new window.google.maps.LatLngBounds();
        path.forEach(({lat, lng}) => bounds.extend(new window.google.maps.LatLng(lat, lng)));
        mapRef.current.fitBounds(bounds);
    };

    const handleRemovePolygon = () => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
            polygonRef.current = null;
            setPolygonPath([]);
            setPolygonError("");
        }
    };

    const handleSubmit = async () => {
        const currentDate = new Date();
        let valid = true;

        // Field validations
        if (!name.trim()) {
            setNameError("Surge Area Name is required");
            valid = false;
        } else {
            setNameError("");
        }
        if (!description.trim()) {
            setDescriptionError("Description is required");
            valid = false;
        } else {
            setDescriptionError("");
        }
        if (polygonPath.length === 0) {
            setPolygonError("Polygon is required");
            valid = false;
        } else {
            setPolygonError("");
        }
        if (!surgeFactor || isNaN(surgeFactor) || surgeFactor <= 0 || !/^\d+(\.\d{1,2})?$/.test(surgeFactor)) {
            setSurgeFactorError("Surge factor must be a positive decimal up to two decimals");
            valid = false;
        } else {
            setSurgeFactorError("");
        }
        if (!startTime || new Date(startTime) <= currentDate) {
            setStartTimeError("Start time must be in the future");
            valid = false;
        } else {
            setStartTimeError("");
        }
        if (!endTime || new Date(endTime) <= new Date(startTime)) {
            setEndTimeError("End time must be after start time");
            valid = false;
        } else {
            setEndTimeError("");
        }

        if (valid) {
            const data = {name, description, surgeFactor, startTime, endTime, coordinates: polygonPath};
            try {
                toast.loading("Submitting...", {id: "surge"});
                const response = await axios.post("/save-surge-area", data);

                if (response.status === 201) {
                    toast.success("Surge area created", {id: "surge"});
                    fetchSurgeAreas();
                    handleClose();
                } else {
                    toast.error("Error saving data", {id: "surge"});
                }
            } catch (error) {
                toast.error("Error saving data", {id: "surge"});
            }
        }
    };

    const modal = (
        <Modal open={open} onClose={handleClose} sx={{overflow:'auto'}}>
            <Box sx={{padding: 4, backgroundColor: "white", width: '90%', margin: "auto", mt: 4, borderRadius: 2}}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h3" sx={{flexGrow: 1, textAlign: 'center'}}>Add Surge Area</Typography>
                    <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Surge Area Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!nameError}
                            helperText={nameError}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!descriptionError}
                            helperText={descriptionError}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Surge Factor"
                            value={surgeFactor}
                            onChange={(e) => setSurgeFactor(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!surgeFactorError}
                            helperText={surgeFactorError}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="datetime-local"
                            placeholder=""
                            label="Start Time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!startTimeError}
                            helperText={startTimeError}
                            InputLabelProps={{
                                shrink: true, // This keeps the label above the field when a value is present
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="datetime-local"
                            label="End Time"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!endTimeError}
                            helperText={endTimeError}
                            InputLabelProps={{
                                shrink: true, // This keeps the label above the field when a value is present
                            }}
                        />
                    </Grid> <Grid item xs={6}>
                    {/*{polygonPath.length > 0 && (*/}
                    {/*    <IconButton color="secondary" onClick={handleRemovePolygon}><DeleteIcon/></IconButton>*/}
                    {/*)}*/}
                    </Grid>
                </Grid>

                {typeof window.google !== 'undefined' ? (
                    <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)}
                                       onPolygonComplete={handlePolygonComplete}/>
                ) : (
                    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing",'places']}>
                        <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)}
                                           onPolygonComplete={handlePolygonComplete}/>
                    </LoadScript>
                )}
                {polygonError && <Typography color="error" variant="body2" sx={{mt: 1}}>{polygonError}</Typography>}
                <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth
                        sx={{mt: 2}}>Submit</Button>
            </Box>
        </Modal>
    );

    // Remaining component setup remains the same
    const [surgeAreas, setSurgeAreas] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchSurgeAreas = async () => {
        try {
            const response = await axios.get("/surge-areas");

            setSurgeAreas(response.data);
            setLoading(false);
            console.log(surgeAreas
            )
        } catch (err) {
            setLoading(false);
            toast.error("Failed to fetch surge areas.");
        }
    };
    useEffect(() => {
        fetchSurgeAreas();
    }, []);
    const handleEdit = (area) => { console.log("Editing area:", area); };
    const handleDelete = async (id) => {
        try {
            await axios.delete(`/surge-areas/${id}`);
            setSurgeAreas(surgeAreas.filter((area) => area._id !== id));
            toast.success("Surge area deleted.");
        } catch (err) {
            toast.error("Failed to delete surge area.");
        }
    };
    const areas = loading ? (
        <Box display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="rectangular" width="100%" height={200} />
        </Box>
    ) : (
        <Box>
            <Typography variant="h4" gutterBottom>Surge Areas</Typography>
            {surgeAreas.length === 0 ? <Typography>No surge areas found</Typography> : (
                <Box display="flex" flexWrap="wrap" gap={2}>
                    {surgeAreas.map((area) => (
                        <SurgeAreaCard key={area._id} area={area} onEdit={handleEdit} onDelete={() => handleDelete(area._id)} />
                    ))}
                </Box>
            )}
        </Box>
    );



    return (
        <>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button variant="contained" color="primary" onClick={handleOpen}>Add Surge Area</Button>
            </Box>
            {modal}
            {areas}

        </>
    );
};
