import React, {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {ContentState, convertFromHTML, convertToRaw, EditorState,} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let Editor = () => <React.Fragment/>;

const LegalTerms = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const getData = () => {
        // toast.loading("fetching data", { id: "data" });

        axios
            .get("/get-legal/v1?long=73.074305&lat=33.642071")
            .then(({data}) => {
                const contentDataState = ContentState.createFromBlockArray(
                    convertFromHTML(data.data.legal)
                );
                const editorDataState = EditorState.createWithContent(contentDataState);

                console.log("Data", editorDataState);
                setEditorState(editorDataState);
                toast.success("Data Updated", {id: "data"});
            })
            .catch((e) => {
                console.error(e);
                toast.error("Error Fetching Data 11", {id: "data"});
            });
    };
    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = (event) => {
        axios
            .post("/legal/v1", {
                legal: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            })
            .then(({data}) => {
                toast.success("Legal Terms Updated", {id: "saA"});
                getData();
            })
            .catch(() => {
                toast.error("Unable to update", {id: "saA"});
            });
    };

    const {t} = useTranslation();

    useEffect(() => {
        Editor = require("react-draft-wysiwyg").Editor;
        setEditorState(EditorState.createEmpty());
    }, []);
    return (
        <>
            <Typography variant="h1" mb={3}>
                {t("pages.title.legalTerms")}
            </Typography>
            <Grid>
                <Editor
                    editorStyle={{
                        width: "100%",
                        minHeight: 100,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "lightgray",
                        backgroundColor: "white",
                        padding: "2rem",
                    }}
                    editorState={editorState}
                    onEditorStateChange={(editorState) => setEditorState(editorState)}
                />
                {/* <textarea
          style={{ width: "100%", height: 200 }}
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    style={{marginTop: 15}}
                >
                    Submit
                </Button>
            </Grid>
        </>
    );
};

export default LegalTerms;
