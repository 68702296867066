import React from "react";
import Avatar from "@mui/material/Avatar";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { useAuth } from "app/context/AuthContext";
import {FILE_URL} from "../../../../constants";

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const auth = useAuth();
  const onLogout = async () => {
    await auth?.logout();
    // navigate("/");
  };

  const authUser = JSON.parse(sessionStorage.getItem("authUser"));
  // console.log(authUser);
  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={FILE_URL + authUser?.profilePic}
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          />
        }
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
          }}
        >
          {/* <Avatar
            src={FILE_URL + authUser.profilePic}
            alt={authUser.name}
            sx={{ width: 60, height: 60, mb: 2 }}
          /> */}
          <Typography variant={"h5"}>{authUser?.name}</Typography>
          <Typography variant={"body1"} color="text.secondary">
            {authUser?.email}
          </Typography>
        </Div>
        <Divider />
        <nav style={{ minWidth: 220 }}>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton onClick={() => navigate("/admin/profile")}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" sx={{ my: 0 }} />
            </ListItemButton>

            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
