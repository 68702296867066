import React, {useState} from "react";
import Datagrid from "../components/Datagrid";
import {Avatar, Box, Button, Grid, IconButton, ListItem, Modal, TextField, Typography} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import {fileURL} from "../context/constants";
import Backdrop from "@mui/material/Backdrop";
import {useDropzone} from "react-dropzone";
import List from "@mui/material/List";
import DndWrapper from "app/layouts/shared/DndWrapper";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    height: "80vh",
    width: "40%",
    overflowY: "auto",
};

const SubAdmins = () => {
    const [open, setOpen] = React.useState(false);
    const [adding, setAdding] = useState(false);
    const [id, setId] = useState();
    const [email, setEmail] = useState({value: "", error: false, helperText: ""});
    const [password, setPassword] = useState({value: "", error: false, helperText: ""});
    const [firstName, setFirstName] = useState({value: "", error: false, helperText: ""});
    const [lastname, setLastname] = useState({value: "", error: false, helperText: ""});
    const [mobile, setMobile] = useState({value: "", error: false, helperText: ""});

    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        {
            field: "image",
            width: 150,
            renderCell: (params) => <Avatar alt="Cindy Baker" src={fileURL + params.row.image}/>,
        },
        {field: "lastname", headerName: "Last Name", width: 150},
        {field: "email", headerName: "Email", width: 150},
        {field: "phone", headerName: "Phone", width: 150},
        {
            headerName: "Registered At",
            field: "createdAt",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body2">
                    {new Date(params.row.createdAt).toLocaleString()}
                </Typography>
            ),
        },
        {
            headerName: "Update",
            field: "id",
            sortable: false,
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        handleOpen(params.row);
                    }}
                >
                    Update
                </Button>
            ),
        },
        {
            headerName: "Delete",
            field: "_id",
            sortable: false,
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        handleDelete(params.row._id);
                    }}
                >
                    Delete
                </Button>
            ),
        },
    ];

    const openAddModal = () => {
        setFirstName({value: "", error: false, helperText: ""});
        setLastname({value: "", error: false, helperText: ""});
        setEmail({value: "", error: false, helperText: ""});
        setMobile({value: "", error: false, helperText: ""});
        setPassword({value: "", error: false, helperText: ""});
        setOpen(true);
        setAdding(true);
    };

    const handleOpen = (data) => {
        setOpen(true);
        setFirstName({value: data.name, error: false, helperText: ""});
        setLastname({value: data.lastname, error: false, helperText: ""});
        setEmail({value: data.email, error: false, helperText: ""});
        setMobile({value: data.phone, error: false, helperText: ""});
        setId(data._id);
    };

    const [refetch, setRefetch] = useState(1);

    const handleSubmit = (event) => {
        // Validation
        if (!firstName.value) {
            setFirstName({...firstName, error: true, helperText: "Provide a valid name"});
            return;
        }
        if (!lastname.value) {
            setLastname({...lastname, error: true, helperText: "Provide a valid name"});
            return;
        }
        if (!mobile.value) {
            setMobile({...mobile, error: true, helperText: "Provide a valid phone number"});
            return;
        }

        const formData = new FormData();
        formData.append("image", acceptedFiles[0]);
        formData.append("email", email.value);
        formData.append("name", firstName.value);
        formData.append("lastname", lastname.value);
        formData.append("phone", mobile.value);
        formData.append("password", password.value);

        if (adding) {
            toast.loading("Adding SubAdmin", {id: "saA"});
            axios
                .post("/addSubAdmin", formData)
                .then(({data}) => {
                    toast.success("SubAdmin Added", {id: "saA"});
                    setRefetch(refetch + 1);
                    handleClose();
                })
                .catch(() => {
                    toast.error("Unable to add SubAdmin", {id: "saA"});
                });
        } else {
            formData.append("id", id);
            toast.loading("Updating SubAdmin", {id: "sa"});
            axios
                .post("/updateSubAdmin", formData)
                .then(({data}) => {
                    toast.success("SubAdmin Updated", {id: "sa"});
                    setRefetch(refetch + 1);
                    handleClose();
                })
                .catch(() => {
                    toast.error("Unable to update SubAdmin", {id: "sa"});
                });
        }
    };

    const handleDelete = (id) => {
        toast.loading("Deleting SubAdmin", {id: "DSA"});
        axios
            .get("/deleteSubAdmin/".concat(id))
            .then(({data}) => {
                toast.success("SubAdmin Deleted", {id: "DSA"});
                setRefetch(refetch + 1);
            })
            .catch(() => {
                toast.error("Unable to delete SubAdmin", {id: "DSA"});
            });
    };

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles: 1,
    });
    const files = acceptedFiles.map((file) => (
        <ListItem selected key={file.path} sx={{width: "auto", mr: 1}}>
            {file.path} - {file.size} bytes
        </ListItem>
    ));

    const {t} = useTranslation();

    return (
        <>
            <Grid container justifyContent="space-between">
                <Typography variant="h1" mb={3}>
                    {t("pages.title.subAdmins")}
                </Typography>
                <Grid item></Grid>
                <Grid item>
                    <Button variant="contained" onClick={openAddModal}>
                        Add
                    </Button>
                </Grid>
            </Grid>

            {/* Modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={style} padding={2}>

                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography variant="h3" sx={{flexGrow: 1, textAlign: 'center'}}>SubAdmin Details</Typography>
                        <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                    </Box>

                    {/* Form Fields */}
                    <Grid container spacing={2} direction="column">
                        {[
                            {label: "First Name", state: firstName, setState: setFirstName},
                            {label: "Last Name", state: lastname, setState: setLastname},
                            {label: "Email", state: email, setState: setEmail},
                            {label: "Mobile", state: mobile, setState: setMobile},
                            {label: "Password", state: password, setState: setPassword, type: "password"},
                        ].map(({label, state, setState, type = "text"}, index) => (
                            <Grid container key={index} spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography variant="body2" marginY="20px">
                                        {label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={state.value}
                                        onChange={(e) => setState({...state, value: e.target.value})}
                                        error={state.error}
                                        helperText={state.helperText}
                                        type={type}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Image Upload */}
                    <Grid container spacing={2} direction="column" mt={2}>
                        <Typography variant="body2" marginY="20px">
                            {/*{t("pages.label.uploadImage")}*/}
                            Image
                        </Typography>
                        <Grid item>
                            <DndWrapper {...getRootProps()} sx={{border: "2px dashed #007BFF", padding: "20px"}}>
                                <input {...getInputProps()} />
                                {files.length > 0 ? (
                                    <List>{files}</List>
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        Drag & drop a file here, or click to select one
                                    </Typography>
                                )}
                            </DndWrapper>
                        </Grid>
                    </Grid>

                    {/* Submit Button */}
                    <Grid container justifyContent="center" sx={{marginTop: 3}}>
                        <Button variant="contained" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Grid>
                </Box>
            </Modal>

            {/* Data Grid */}
            <Datagrid columns={columns} refetch={refetch} handleOpen={handleOpen} url='/subAdmins'/>
        </>
    );
};

export default SubAdmins;
