import React, { createContext, useContext, useState, useEffect } from "react";
import { checkAuthStatus, loginUser, logoutUser, signupUser } from "../apis/api-communicator";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState(null);
  const [accessToken, setAccessToken] = useState();
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const data = await checkAuthStatus();
        if (data) {
          setUser({ email: data.email, name: data.name, id: data.id });
          setIsLoggedIn(true);
          setRole(data.role);
        }
      } catch (error) {
        console.log("auth failed", error);
      } finally {
        setLoading(false); // Set loading to false once check is complete
      }
    };

    checkStatus();
  }, []);

  const login = async (email, password) => {
    const data = await loginUser(email, password);
    if (data) {
      setUser({ email: data.email, name: data.name, id: data.id });
      setRole(data.role);
      setAccessToken(data.token);
      localStorage.setItem('role', data.role);
      setIsLoggedIn(true);
      return data;
    }
  };

  const signup = async (name, email, password) => {
    const data = await signupUser(name, email, password);
    if (data) {
      setUser({ email: data.email, name: data.name });
      setIsLoggedIn(true);
    }
  };

  const logout = async () => {
    await logoutUser();
    setIsLoggedIn(false);
    setRole("");
    localStorage.removeItem('role');
    setUser(null);
  };

  const value = {
    user,
    isLoggedIn,
    role,
    login,
    logout,
    signup,
    accessToken,
    loading, // Include loading in context value
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
