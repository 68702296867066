import React, { useEffect, useState } from "react";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import axios from "axios"; // Import Axios

// Updated validation schema
const validationSchema = yup.object({
    baseFare: yup
        .number("Enter a valid number")
        .positive("Must be a positive value")
        .required("Base Fare is required"),
    distanceRate: yup
        .number("Enter a valid number")
        .positive("Must be a positive value")
        .required("Distance Rate is required"),
    timeRate: yup
        .number("Enter a valid number")
        .positive("Must be a positive value")
        .required("Time Rate is required"),
    bookingFeeStandard: yup
        .number("Enter a valid number")
        .positive("Must be a positive value")
        .required("Booking Fee Standard is required"),
    bookingFeePremium: yup
        .number("Enter a valid number")
        .positive("Must be a positive value")
        .required("Booking Fee Premium is required"),
    bookingFeeSurge: yup
        .number("Enter a valid number")
        .positive("Must be a positive value")
        .required("Booking Fee Surge is required"),
});

const Pricing = () => {
    const { t } = useTranslation();
    const [formValues, setFormValues] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitPricing = async (values) => {
        try {
            setIsSubmitting(true);
            toast.loading("Submitting Pricing Details...", { id: "pricing" });
            await axios.post("/pricing", values);
            toast.success("Pricing details submitted successfully!", { id: "pricing" });
            setIsSubmitting(false);
        } catch (e) {
            setIsSubmitting(false);
            toast.error("Failed to submit pricing details", { id: "pricing" });
        }
    };

    const fetchPricingData = async () => {
        try {
            const { data } = await axios.get("/pricing");
            setFormValues({
                baseFare: data.baseFare || "",
                distanceRate: data.distanceRate || "",
                timeRate: data.timeRate || "",
                bookingFeeStandard: data.bookingFeeStandard || "",
                bookingFeePremium: data.bookingFeePremium || "",
                bookingFeeSurge: data.bookingFeeSurge || "",
            });
        } catch (e) {
            toast.error("Failed to fetch pricing details", { id: "fetch" });
        }
    };

    useEffect(() => {
        fetchPricingData();
    }, []);

    return (
        <Div
            sx={{
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "top",     // Center vertically
                minHeight: "100vh",       // Full height of the viewport
                padding: "16px",
            }}
        >
            <Card sx={{ width: "100%", maxWidth: 720 }}>
                <CardContent sx={{ p: 4 }}>
                    {/* Title always visible */}
                    <Typography variant={"h1"} mb={3} align="center">
                        {t("pages.title.pricing")}
                    </Typography>

                    {/* Show Skeleton Loader for Form Fields while fetching data */}
                    {formValues === null ? (
                        <div style={{ padding: "20px" }}>
                            <Skeleton variant="rectangular" height={40} width="100%" />
                            <Skeleton variant="rectangular" height={40} width="100%" style={{ marginTop: 10 }} />
                            <Skeleton variant="rectangular" height={40} width="100%" style={{ marginTop: 10 }} />
                            <Skeleton variant="rectangular" height={40} width="100%" style={{ marginTop: 10 }} />
                            <Skeleton variant="rectangular" height={40} width="100%" style={{ marginTop: 10 }} />
                            <Skeleton variant="rectangular" height={40} width="100%" style={{ marginTop: 10 }} />
                        </div>
                    ) : (
                        <Formik
                            initialValues={formValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true);
                                await onSubmitPricing(values);
                                setSubmitting(false);
                            }}
                            enableReinitialize // Ensures the form updates when initialValues change
                        >
                            {({ isSubmitting, dirty }) => (
                                <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="baseFare"
                                            label="Base Fare"
                                            autoComplete="baseFare"
                                            type="number"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="distanceRate"
                                            label="Distance Rate"
                                            autoComplete="distanceRate"
                                            type="number"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="timeRate"
                                            label="Time Rate"
                                            autoComplete="timeRate"
                                            type="number"
                                        />
                                    </Div>

                                    <Typography variant="h6" mb={2}>
                                        Booking Fees
                                    </Typography>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="bookingFeeStandard"
                                            label="Booking Fee Standard"
                                            autoComplete="bookingFeeStandard"
                                            type="number"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="bookingFeePremium"
                                            label="Booking Fee Premium"
                                            autoComplete="bookingFeePremium"
                                            type="number"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="bookingFeeSurge"
                                            label="Booking Fee Surge"
                                            autoComplete="bookingFeeSurge"
                                            type="number"
                                        />
                                    </Div>

                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isSubmitting}
                                        disabled={!dirty}  // Disable button if form is unchanged
                                    >
                                        Update Pricing
                                    </LoadingButton>
                                </Form>
                            )}
                        </Formik>
                    )}
                </CardContent>
            </Card>
        </Div>
    );
};

export default Pricing;
