import React, { useState, useEffect } from "react";
import { Grid, TextField, IconButton, InputAdornment, Typography, CircularProgress } from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import toast from "react-hot-toast";
import {useAuth} from "../context/AuthContext";

const CorporateHome = () => {
    const [accessCode, setAccessCode] = useState('');
    const [loading, setLoading] = useState(true); // Initially set loading to true

    // Simulate API call to fetch access code
    useEffect(() => {
        // Assume fetching access code asynchronously
        setTimeout(() => {
            const fetchedAccessCode = "ABCDE12345"; // Replace with actual API call
            setAccessCode(fetchedAccessCode);
            setLoading(false); // Set loading to false after fetching data
        }, 2000); // Simulating 2 seconds delay
    }, []); // Empty dependency array ensures useEffect runs once on component mount

    const handleCopyAccessCode = () => {
        navigator.clipboard.writeText(accessCode)
            .then(() => {
                toast.success("Access code copied to clipboard");
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };
const auth = useAuth();
    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={3}>
                <Typography variant="h6" gutterBottom>
                    Access Code
                </Typography>
            </Grid>
            <Grid item xs={9}>
                {loading ? (
                    <CircularProgress size={24} /> // Show loader while fetching data
                ) : (
                    <TextField
                        id="access-code"
                        variant="outlined"
                        fullWidth
                        value={accessCode}
                        disabled
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleCopyAccessCode} aria-label="copy">
                                        <FileCopyIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default CorporateHome;
