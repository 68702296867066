// Drivers.js
import React, {useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import Datagrid from "app/components/Datagrid";
import DriverDetailsModal from "../components/DriverManagement/DriverDetailsModal";
import SearchBar from "../shared/SearchBar";
import CopyWrapper from "../shared/CopyWrapper";
import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import BlockIcon from "@mui/icons-material/Block";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

const Drivers = () => {
    const [open, setOpen] = useState(false);
    const [carData, setCarData] = useState({});
    const [driverDetails, setDriverDetails] = useState(null);
    const [modalData, setModalData] = useState(null);
    const {t} = useTranslation();
    const [refetch, setRefetch] = useState(1);

    const handleClose = () => setOpen(false);

    const handleOpen = async (driverData, id) => {
        try {
            toast.loading('Loading')
            const {data} = await axios.get(`/car-data/${id}`);
            setCarData(data.car);
            setDriverDetails(data.driverDetails);
            console.log(driverData.driverId)
            setModalData(driverData.driverId);
            setOpen(true);
            toast.dismiss()
        } catch {
            toast.error("Unable to load Data", {id: "drivers"});
        }
    };

    const verifyDriver = async (id) => {
        toast.loading("Verifying", {id: "drivers"});
        try {
            await axios.get(`/verifyDriver/${id}`);
            toast.success("Verified Successfully", {id: "drivers"});
            handleClose();
            setRefetch(refetch + 1);
        } catch {
            toast.error("Unable to Verify", {id: "drivers"});
        }
    };
    const blockDriver = async (id, block) => {
        toast.loading("Blocking", {id: "drivers"});
        try {
            await axios.post(`/block-driver/`,
                {id, block});
            toast.success("Blocked Successfully", {id: "drivers"});
            handleClose();
            setRefetch(refetch + 1);
        } catch {
            toast.error("Unable to block", {id: "drivers"});
        }
    };
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (query) => {
        setSearchQuery(query);
        // Optionally, trigger data fetching or filtering based on the search query
    };
    const columns = [
        {
            headerName: "ID",
            width: 275,
            renderCell: (params) => (
                <Typography variant="body"><CopyWrapper>{params.row?.driverId?._id}</CopyWrapper></Typography>
            ),
        }, {
            headerName: "Name",
            field: 'name',

            renderCell: (params) => (
                <Typography variant="body">{params.row?.driverId?.name}</Typography>
            ),
        },
        {
            field: "email",
            renderCell: (params) => (
                <Typography variant="body">{params.row?.driverId?.email}</Typography>
            ),
            headerName: "Email",
            width: 250,
        },
        {
            field: "phone",
            renderCell: (params) => (
                <Typography variant="body">{params.row?.driverId?.phone}</Typography>
            ),
            headerName: "Phone",

        },
        {
            field: "isVerified",
            renderCell: (params) => (
                <>
                    <Chip
                        label={params.row?.isVerified ? "Verified" : "Pending"}
                        color={params.row?.isVerified ? "success" : "warning"}
                        size="medium"
                        icon={
                            params.row?.isVerified ? (
                                <CheckCircleIcon style={{color: "green"}}/>
                            ) : (
                                <HourglassEmptyIcon style={{color: "pending"}}/>
                            )
                        }
                    />
                    {/* Add more Chip components here if needed */}
                </>),
            headerName: "Verified",
            width: 100,
        }, {
            field: "isBlocked",
            renderCell: (params) => (
                <>
                    <Chip
                        label={params.row?.isBlocked ? "Blocked" : "Active"}
                        color={params.row?.isBlocked ? "error" : "success"}
                        size="medium"
                        icon={
                            params.row?.isBlocked ? (
                                <CancelIcon style={{color: "red"}}/>

                            ) : (
                                <CheckCircleIcon style={{color: "green"}}/>
                            )
                        }
                    />
                    {/* Add more Chip components here if needed */}
                </>),
            headerName: "Status",
            width: 100,
        }, {
            field: 'veriffStatus',
            renderCell: (params) => {
                const status = params.row?.veriffStatus;

                const getStatusProps = () => {
                    switch (status) {
                        case 'approved':
                            return {label: "Approved", color: "success", icon: <CheckCircleIcon/>};
                        case 'resubmission_requested':
                            return {label: "Resubmission Requested", color: "warning", icon: <ReplayIcon/>};
                        case 'review':
                            return {label: "Under Review", color: "info", icon: <HourglassEmptyIcon/>};
                        case 'declined':
                            return {label: "Declined", color: "error", icon: <CancelIcon/>};
                        case 'expired':
                            return {label: "Expired", color: "default", icon: <ErrorOutlineIcon/>};
                        case 'abandoned':
                            return {label: "Abandoned", color: "default", icon: <DoNotDisturbIcon/>};
                        case 'pending':
                            return {label: "Pending", color: "warning", icon: <HourglassEmptyIcon/>};
                        case 'not_applied':
                            return {label: "Not Applied", color: "default", icon: <BlockIcon/>};
                        default:
                            return {label: "Unknown", color: "default", icon: <ErrorOutlineIcon/>};
                    }
                };

                const {label, color, icon} = getStatusProps();

                return (
                    <Chip
                        label={label}
                        color={color}
                        size="medium"
                        icon={icon}
                        variant="outlined"
                    />
                );
            },
            headerName: "Veriff",
            width: 100,
        },
        // {
        //     field: "Verified",
        //     renderCell: (params) => (
        //         <Typography variant="body">{params.row?._id}</Typography>
        //     ),
        //     headerName: "Status",
        //     width: 150,
        // },
        {
            headerName: "Registered At",
            field: "_id",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body">
                    {new Date(params.row.createdAt).toLocaleString()}
                </Typography>
            ),
        },
        {
            headerName: "Details",
            field: "id",
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpen(params.row, params.row.driverId._id)}
                >
                    Details
                </Button>
            ),
        },
    ];

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" mb={0}>
                <Grid item>
                    <Typography variant="h1">
                        {t("pages.title.drivers")}
                    </Typography>
                </Grid>
                <Grid item>
                    <SearchBar onSearch={handleSearch}/>
                </Grid>
            </Grid>

            <Datagrid url="/drivers" columns={columns} refetch={refetch} filter={searchQuery}/>

            <DriverDetailsModal
                open={open}
                handleClose={handleClose}
                modalData={modalData}
                carData={carData}
                driverDetails={driverDetails}
                verifyDriver={verifyDriver}
                blockDriver={blockDriver}

            />
        </>
    );
};

export default Drivers;
